<script>
  import { isExternalUrl, correctedUrl } from "$lib/utilities/url-helpers";
  import { Link, GridContainer } from "@ObamaFoundation/of-design-system";
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";
  import { getContext } from "svelte";

  export let url;
  export let id;
  export let variant = "small";
  export let label = "";

  let className = "";
  export { className as class };

  export let haspopup = false;
  export let expanded = false;

  let isExternal;
  let linkToRender;
  $: {
    isExternal = isExternalUrl(url, $page?.url?.origin);
    linkToRender = correctedUrl(url);
  }

  const isContainerized = !!getContext("containerized");
</script>

{#if variant === "inline"}
  <Link
    href={linkToRender}
    {isExternal}
    on:click
    aria-haspopup={haspopup}
    aria-expanded={haspopup ? expanded : null}
    target={isExternal ? "_blank" : null}
    rel={isExternal ? "noopener noreferrer" : null}
    {label}
    {id}
    {variant}
  />
{:else if isContainerized}
  <div class={className}>
    <Link
      href={linkToRender}
      {isExternal}
      on:click
      aria-haspopup={haspopup}
      aria-expanded={haspopup ? expanded : null}
      target={isExternal ? "_blank" : null}
      rel={isExternal ? "noopener noreferrer" : null}
      {label}
      {id}
      {variant}
    />
  </div>
{:else}
  <GridContainer containerClass={className}>
    <Link
      href={linkToRender}
      {isExternal}
      on:click
      aria-haspopup={haspopup}
      aria-expanded={haspopup ? expanded : null}
      target={isExternal ? "_blank" : null}
      rel={isExternal ? "noopener noreferrer" : null}
      {label}
      {id}
      {variant}
    />
  </GridContainer>
{/if}
