<script>
  // @ts-check
  import ItemPreviewList from "$lib/components/ItemPreviewList.svelte";
  import {
    getPaginatedItemPreviewListItems,
    getThemedItemIds
  } from "$lib/utilities/contentTypes/itemPreviewList";
  // eslint-disable-next-line @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";
  import { filterItemsStore } from "$lib/stores";
  import { onDestroy } from "svelte";

  const Constants = /** @type {const} */ ({
    pressReleasesPath: "/press-releases",
    maximumItemsInPressReleasesPage: 8
  });

  /** @type { string } */
  let className = "";
  export { className as class };

  export let data;
  export let itemsPerPage = 36;

  const urlPathName = $page?.url?.pathname;
  /** @type { () => number } */
  const getCurrentItemsPerPage = () => {
    if (
      urlPathName === `${Constants.pressReleasesPath}/` ||
      urlPathName === Constants.pressReleasesPath
    ) {
      return Constants.maximumItemsInPressReleasesPage;
    }
    return itemsPerPage;
  };

  // Tag automation gets the preference
  const isPopulatedByPathOrTag = !!data.fields?.itemsSource || !!data.fields?.pathQuery;
  const itemsData = isPopulatedByPathOrTag ? data.children : data.fields?.listItems;
  const itemsFormFactor = data.fields.itemsFormFactor;

  const themedItemIds = getThemedItemIds(data.fields?.themedItems);
  const featuredItems = data.fields?.featuredItems ?? [];
  const excludedItems = data.fields?.excludedItems ?? [];
  const sourceTagItemId = data.fields?.itemsSource ? data.fields?.itemsSource.sys?.id : null;

  const currentItemsPerPage = getCurrentItemsPerPage();

  let filteredItems = [];

  $: currentUrl = $page?.url;

  $: paginatedItems =
    getPaginatedItemPreviewListItems({
      itemsData: filteredItems,
      itemsFormFactor,
      themedItemIds,
      featuredItems,
      excludedItems,
      sourceTagItemId,
      itemsPerPage: currentItemsPerPage,
      isPopulatedByPathOrTag
    }) ?? [];

  /**
   * TODO: Refactor this history logic in the future ticket for back button behavior
   */
  const setHistory = () => {
    const newUrl = new URL(currentUrl.href);

    newUrl.searchParams.set("ipl", data.sys.id);
    newUrl.searchParams.set("page", "1");

    history?.pushState(
      {
        ipl: data.sys.id,
        page: 1
      },
      "",
      newUrl
    );
  };

  const unsubscribe = filterItemsStore.subscribe(async (filters) => {
    filteredItems = [...itemsData].filter((item) => {
      /**
       * There are places in the source code where tags are transformed differently. For example, on
       * /stories/all/, we use item.contentTags to get the tags. On /stories/, however, we use
       * item.fields.contentTags. I just wanted to accommodate for the different transformations just in
       * case we need to use this elsewhere. - Christina
       */
      const tags = item.contentTags || item.fields?.contentTags || [];

      const filterGroups = filters.reduce((groups, filter) => {
        if (!groups[filter.group]) {
          groups[filter.group] = [];
        }

        groups[filter.group].push(filter.label);

        return groups;
      }, {});

      return Object.entries(filterGroups).every(([, values]) => {
        return tags.find((tag) => {
          return values.includes(tag.fields.name || tag.name);
        });
      });
    });

    if (currentUrl) {
      setHistory();
    }
  });

  onDestroy(() => {
    unsubscribe();
  });
</script>

<ItemPreviewList class={className} {itemsFormFactor} items={paginatedItems} iplId={data.sys?.id} />
