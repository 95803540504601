<script>
  import DonorNames from "./Contributors/DonorNames.svelte";
  import {
    Pagination,
    CenteredLayout,
    GridContainer,
    Search
  } from "@ObamaFoundation/of-design-system";

  export let items = [];
  export let itemsPerPage = 10;
  export let formName;
  export let containerRef;
  export let formSubmitErrorMessage;
  export let pageNumber = 1;

  /**
   * for use in parent component to paginate given list of items based on page size
   *
   * @param items
   * @param itemsPerPage
   */
  const paginateItems = (items = [], itemsPerPage = 1) => {
    const numOfPages = Math.ceil(items.length / itemsPerPage);
    const paginatedItems = Array(numOfPages)
      .fill()
      .map((_, i) => {
        return items.slice(i * itemsPerPage, (i + 1) * itemsPerPage);
      });

    return {
      numOfPages,
      paginatedItems
    };
  };

  $: ({ numOfPages, paginatedItems } = paginateItems(items, itemsPerPage));
  $: activeItems = paginatedItems?.[0] ?? [];
  function onNavigate(event) {
    let pageNumber = event.detail.page;
    activeItems = paginatedItems[pageNumber - 1];
    window.scrollTo({
      top: containerRef?.offsetTop || 0,
      behavior: "smooth"
    });
  }
</script>

<CenteredLayout title="$200 to $9,999" class="mb-7.5 lg:mb-15">
  <div bind:this={containerRef}>
    <Search
      errorMessage={formSubmitErrorMessage}
      searchId={`${formName}-searchableList-searchBar`}
      on:clear
      on:submit
    />
    <DonorNames
      contributors={activeItems}
      liClasses="border-b-thin body-md py-7.5 last:border-b-0"
    />
  </div>
</CenteredLayout>
<GridContainer>
  <Pagination
    class="mb-100"
    on:navigate={onNavigate}
    totalPages={numOfPages}
    initialActivePage={pageNumber}
  />
</GridContainer>
<div aria-live="polite" aria-atomic="true" class="sr-only">Found {items.length} items</div>
