<script>
  import { setContext } from "svelte";
  import { fade } from "svelte/transition";
  import { v4 as uuid } from "@lukeed/uuid";
  import {
    getSpotifyIframeSrcFromShareUrl,
    getSpotifyEmbedType
  } from "$lib/utilities/components/embeds";
  import Link from "$lib/components/Link.svelte";

  export let data;

  const { url, title, displayTitle } = data.fields;

  const spotifyIframeSrc = getSpotifyIframeSrcFromShareUrl(url);

  const embedType = getSpotifyEmbedType(url);
  const shouldHaveTallerEmbed = ["playlist", "album", "artist"].includes(embedType);

  setContext("containerized", true);
</script>

{#if spotifyIframeSrc}
  {#if displayTitle}
    <Link id="spotify-embed-link-{uuid()}" class="pb-6" variant="small" {url} label={title} />
  {/if}
  <iframe
    id="spotify-embed-iframe-{uuid()}"
    {title}
    src={spotifyIframeSrc}
    in:fade
    width="100%"
    height={shouldHaveTallerEmbed ? "460" : "232"}
    frameBorder="0"
    allowfullscreen=""
    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
    loading="lazy"
    data-chromatic="ignore"
  />
{/if}
