<script>
  import { GridContainer, WordCloud } from "@ObamaFoundation/of-design-system";
  import IntersectionObserver from "svelte-intersection-observer";
  import { getContext } from "svelte";

  export let contentList;
  export let linkLabel;
  export let linkUrl;
  export let animateOnView;
  /** @type { string } */
  let className = "";
  export { className as class };

  /** @type {HTMLElement | undefined} */
  let wordCloudRef;
  /** @type {boolean} */
  let wordCloudIntersecting = false;

  const isContainerized = getContext("containerized");
  const isSectionDescendant = !!getContext("sectionDescendant");
  const isDonateDescendant = !!getContext("donateDescendant");

  const intersectionRootMargin = "0px 0px -10% 0px";
</script>

<IntersectionObserver
  once
  rootMargin={intersectionRootMargin}
  element={wordCloudRef}
  bind:intersecting={wordCloudIntersecting}
>
  {#if (!isContainerized || isSectionDescendant) && !isDonateDescendant}
    <GridContainer containerClass={className}>
      <WordCloud
        {contentList}
        {linkLabel}
        {linkUrl}
        {animateOnView}
        bind:ref={wordCloudRef}
        isIntersecting={wordCloudIntersecting}
      />
    </GridContainer>
  {:else}
    <WordCloud
      {contentList}
      {linkLabel}
      {linkUrl}
      {animateOnView}
      bind:ref={wordCloudRef}
      isIntersecting={wordCloudIntersecting}
    />
  {/if}
</IntersectionObserver>
