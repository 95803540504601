<script context="module">
  import { buildSrc, srcSetFromWidths, sizesForBreakpoints, SRCSET_WIDTHS } from "$lib/utilities";

  let resizeParams = {};
  // Image sizes (widths) for available breakpoints.
  // These can be set using any unit, but we recommend using vw (50vw, 100vw, etc.)
  // If you don't set these, the image will be full width at all breakpoints.
  let widthsAt = { sm: "100vw", md: "50vw" };
  const sizes = sizesForBreakpoints(widthsAt);

  export const getCallToActionData = ({
    fields: { title, theme, description, action, image },
    sys: { id }
  }) => ({
    title,
    theme: theme ?? "lightBlue",
    featuredImage: image
      ? {
          src: buildSrc(image.fields?.imageAsset),
          alt: image.fields?.altText,
          srcset: srcSetFromWidths(
            image.fields?.imageAsset.fields.file.url,
            SRCSET_WIDTHS,
            resizeParams
          ),
          sizes
        }
      : null,
    description,
    cta: action
      ? {
          text: action.fields?.text,
          url: action.fields?.destination?.fields?.url,
          isExternal: isExternalUrl(action.fields?.destination?.fields?.url),
          id
        }
      : undefined
  });
</script>

<script>
  import CallToAction from "$lib/components/CallToAction.svelte";
  import { isExternalUrl } from "$lib/utilities/url-helpers";

  export let data;

  /** @type { string } */
  let className = "";
  export { className as class };

  const dataProps = getCallToActionData(data);
</script>

<CallToAction class={className} {...dataProps} />
