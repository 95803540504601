import {
  getUserFriendlyCampaignsFromIds,
  EMAIL_ADDRESS_ERROR_MESSAGE,
  ONE_OR_MORE_FAILURES_ERROR_MESSAGE,
  GENERIC_EMAIL_SIGNUP_SALESFORCE_ERROR_MESSAGE
} from "../formFields";

export async function postToSalesforce({ pageUrl, campaignIDs, firstname, lastname, email }) {
  const headers = { "Content-Type": "application/x-www-form-urlencoded" };
  const params = new URLSearchParams({
    leadsource: "web",
    contactsource: pageUrl,
    firstname,
    lastname,
    email,
    campaignids: campaignIDs
  }).toString();

  try {
    const resp = await fetch(`https://cloud.email.obama.org/signup-autocompleteV2?${params}`, {
      method: "post",
      headers
    });

    if (resp.ok) {
      const body = await resp.json();
      // Validation errors from Salesforce Cloud Page are formatted with a 200 response,
      // but the bodies contain a status object with value `fail` and descriptive error msg.
      if (body.status === "fail") {
        // If one or more campaigns has failed, the ids are returned as a CSV the response body as `failedCampaignIds`.
        // To make a user-friendly error message, we translate the campaign ids to user friendly names.
        let failedCampaignIds = body.failedCampaignIds.split(",");
        let failedCampaigns = getUserFriendlyCampaignsFromIds(failedCampaignIds);
        throw new Error(body.msg, { cause: failedCampaigns.join(", ") });
      }
      return { ...body };
    } else {
      throw new Error(`HTTP Error: ${resp.statusCode}`);
    }
  } catch (err) {
    /* eslint-disable no-console*/
    console.error(err);
    let errorMsg = err.toString();
    // Error is unknown. Provide generic error message.
    if (
      errorMsg !== EMAIL_ADDRESS_ERROR_MESSAGE &&
      errorMsg !== ONE_OR_MORE_FAILURES_ERROR_MESSAGE
    ) {
      errorMsg = GENERIC_EMAIL_SIGNUP_SALESFORCE_ERROR_MESSAGE;
    }
    // Adjust Salesforce Error and add failure details
    if (errorMsg === ONE_OR_MORE_FAILURES_ERROR_MESSAGE) {
      errorMsg = `We are experiencing issues with the following newsletters. Please try again or come back soon to sign up: ${err.cause}`;
    }
    throw new Error(`${errorMsg}`);
  }
}
