<script>
  // @ts-check
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";

  import EmailSignupForm from "$lib/components/EmailSignupForm.svelte";
  import { SALESFORCE_CONSTANTS } from "$lib/utilities/constants";
  import { getFullUrl } from "$lib/utilities/url-helpers";
  import { getContext } from "svelte";

  /** @type { string } */
  let className = "";
  export { className as class };

  /** The type of layout under the featured layout
   * @type { "vertical" | "horizontal" }
   */
  export let layoutVariant = "horizontal";

  export let data;
  export let result;
  export let submitButtonId = `gtmEmailSignUp-${data.sys.id}`;

  const fields = data?.fields || data;
  const imageFields = fields?.image?.fields;

  const useNativeFormSubmission = fields.noteLabel && fields.redirectUrl;
  const formProps = useNativeFormSubmission
    ? {
        action: SALESFORCE_CONSTANTS.webToCaseEndPoint,
        method: "POST"
      }
    : {};

  const pageData = getContext("pageData");

  $: redirectUrl = getFullUrl(fields?.redirectUrl, $page?.url?.origin);
  $: formFields = [
    {
      id: "campaignId",
      name: SALESFORCE_CONSTANTS.fieldNames.campaignId,
      type: "hidden",
      value: fields?.campaignId
    },
    {
      id: "redirectUrl",
      name: SALESFORCE_CONSTANTS.fieldNames.redirectUrl,
      type: "hidden",
      value: redirectUrl
    },
    {
      id: "subject",
      name: SALESFORCE_CONSTANTS.fieldNames.subject,
      type: "hidden",
      value: fields?.subject
    },
    {
      id: "Source_URL__c",
      name: SALESFORCE_CONSTANTS.fieldNames.contactSourceUrl,
      type: "hidden",
      value: pageData?.fields.relativeUrl
    },
    {
      id: `form-view-${fields?.campaignId}-firstName`,
      name: SALESFORCE_CONSTANTS.fieldNames.firstName,
      type: "text",
      label: fields?.firstNameLabel,
      required: true,
      errorMessage: fields?.firstNameError
    },
    {
      id: `form-view-${fields?.campaignId}-lastName`,
      name: SALESFORCE_CONSTANTS.fieldNames.lastName,
      type: "text",
      label: fields?.lastNameLabel,
      required: true,
      errorMessage: fields?.lastNameError
    },
    {
      id: `form-view-${fields?.campaignId}-email`,
      name: SALESFORCE_CONSTANTS.fieldNames.email,
      type: "email",
      label: fields?.emailAddressFieldLabel,
      required: true,
      errorMessage: fields?.emailAddressError
    },
    useNativeFormSubmission
      ? {
          id: "note",
          name: SALESFORCE_CONSTANTS.fieldNames.description,
          label: fields?.noteLabel,
          type: "textarea",
          required: false,
          errorMessage: fields?.noteError
        }
      : null,
    {
      id: "termsOfService",
      type: "checkbox",
      label: fields?.termsOfServiceCheckboxLabelMarkdown,
      required: true,
      errorMessage: fields?.termsOfServiceError
    },
    {
      id: submitButtonId,
      type: "submit",
      label: fields?.submitButtonText
    }
  ].filter(Boolean);
</script>

<EmailSignupForm
  campaignId={fields?.campaignId}
  class={className}
  image={imageFields}
  {layoutVariant}
  title={fields?.title}
  description={fields?.description}
  titleConfirmationPage={fields?.successTitle}
  subTitleConfirmationPage={fields?.successSubtitle}
  descriptionConfirmationPage={fields?.successDescription}
  {formProps}
  {formFields}
  bind:result
/>
