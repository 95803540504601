<script>
  import { Link } from "@ObamaFoundation/of-design-system";
  import { textToId } from "$lib/utilities/dom-helpers";
  import Iframe from "./Iframe.svelte";

  export let data;

  let url = data.fields.url;
  let title = data.fields.title;
  let displayTitle = data.fields.displayTitle;
  let description = data.fields?.description;
  // The iframe src attribute expects hardcoded hex value. Using the var(--color-cyan-700) does not override default color
  let playBtnColor = "#000000";
</script>

<div aria-label="SoundCloud clip {title}" class={displayTitle ? "" : "pb-5"}>
  {#if displayTitle}
    <div class="pb-6">
      <Link {url} label={title} />
    </div>
  {/if}
  <Iframe
    class="w-full h-[166px]"
    {title}
    id="soundcloud-{textToId(title)}"
    src="https://w.soundcloud.com/player/?url={url}&color={encodeURIComponent(
      playBtnColor
    )}&show_teaser=false"
  />

  {#if description}
    <p class="mt-4">
      {description}
    </p>
  {/if}
</div>
